import React from 'react'
import { trackEvent } from '../helpers/tracking'
import withStyles from '@material-ui/core/styles/withStyles'
import { Link } from 'gatsby'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
// import Dialog from '@material-ui/core/Dialog'
import ContactForm from './contact-form'
// import SignupForm from './signup-form'
import banner from '../images/spiral-header-small-sizes-white.png'

const styles = theme => ({
  logo: {
    display: 'inline-block',
    marginBottom: 25,
  },
  links: {
    color: theme.palette.primary.contrastText,
    textDecoration: 'underline',
  },
  heading: {
    color: theme.palette.primary.contrastText,
  },
  text: {
    color: theme.palette.primary.contrastText,
  },
  smallprint: {
    marginTop: 30,
    textAlign: 'center',
    color: theme.palette.primary.light,
  },
})

class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showSignupForm: false,
    }
  }

  render() {
    const { classes } = this.props
    return (
      <footer
        style={{
          backgroundColor: '#385D5D',
        }}
      >
        <div
          style={{
            maxWidth: 1200,
            margin: '0 auto',
            padding: '3rem 1.0875rem',
          }}
        >
          <Grid container spacing={24}>
            <Grid item xs={12} sm={6}>
              <Link to="/" className={classes.logo} style={{ maxWidth: 400 }}>
                <img src={banner} alt="Logo" />
              </Link>
              <Grid container spacing={16}>
                <Grid item xs={12} md={4}>
                  <Link to="/payment-methods">
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      className={classes.links}
                    >
                      Payment methods &amp; discounts
                    </Typography>
                  </Link>
                  <Link to="/shipping-returns">
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      className={classes.links}
                    >
                      Shipping &amp; returns
                    </Typography>
                  </Link>
                  <Link to="/links">
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      className={classes.links}
                    >
                      Links
                    </Typography>
                  </Link>
                  <Link to="/privacy" className={classes.links}>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      className={classes.links}
                    >
                      Privacy
                    </Typography>
                  </Link>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Typography gutterBottom className={classes.text}>
                    Tel (International): +44 1509 557 846
                  </Typography>
                  <Typography gutterBottom className={classes.text}>
                    Tel (UK): 01509 557 846
                  </Typography>
                  <Typography gutterBottom className={classes.text}>
                    Hours: 10:00am to 8:00pm (GMT)
                  </Typography>
                </Grid>
              </Grid>
              <Button
                onClick={() =>
                  trackEvent({
                    category: 'Engagement',
                    action: 'Join mailing list',
                  })
                }
                href={'http://eepurl.com/gbnRyz'}
                target="_blank"
                variant="contained"
                color="secondary"
                size="large"
                style={{ marginTop: 16 }}
              >
                Join mailing list
              </Button>
              {/* <Dialog
                open={this.state.showSignupForm}
                onClose={() => this.setState({ showSignupForm: false })}
              >
                <SignupForm />
              </Dialog> */}
            </Grid>
            {typeof window !== 'undefined' && window.location.pathname !== '/contact' && (
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="h6"
                  className={classes.heading}
                  gutterBottom
                >
                  Contact
                </Typography>
                <ContactForm formName="footer" />
              </Grid>
            )}
          </Grid>
        </div>
      </footer>
    )
  }
}

export default withStyles(styles)(Footer)
