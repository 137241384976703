import React from 'react'
import PropTypes from 'prop-types'
import { trackEvent } from '../helpers/tracking'
import { submitOrder, calculateTotalPrice } from '../helpers/shopping-cart'
import ShoppingCartContext from '../context/shopping-cart'
import withStyles from '@material-ui/core/styles/withStyles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import AddressForm from './address-form'
import PaymentForm from './payment-form'
import Review from './review-order'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'

const styles = theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      padding: theme.spacing.unit * 3,
    },
  },
  stepper: {
    padding: `${theme.spacing.unit * 3}px 0 ${theme.spacing.unit * 5}px`,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit,
  },
})

const steps = ['Your details', 'Payment details', 'Review your order']

const errors = [
  'Bad Referrer - Access Denied',
  'Malformed Referrer - Access Denied',
  'Missing Referrer - Access Denied',
  'Error: GET request',
  'Error: Bad or Missing Recipient',
  'Error: Too many Recipients',
  'Error: Blank Fields',
]

class Checkout extends React.Component {
  state = {
    activeStep: 0,
    email: '',
    phone: '',
    firstName: '',
    lastName: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    paymentMethod: '',
    addToMailingList: false,
    recordsCleaned: false,
    instructions: '',
    error: false,
  }

  componentDidMount() {
    trackEvent({
      category: 'Checkout',
      action: 'Enter flow',
    })
  }

  handleSubmit = (items, successCallback) => {
    submitOrder(items, this.state)
      .then(({ data }) => {
        const response = document.createElement('html')
        response.innerHTML = data
        const heading = response.querySelector('th.c1')
        const message = heading && heading.innerText
        if (errors.includes(message)) {
          throw new Error(message)
        } else {
          successCallback()
          this.handleNext()
        }
      })
      .catch(e => {
        console.error(e)
        this.setState({ error: true })
      })
  }

  handleNext = () => {
    this.setState(state => ({
      activeStep: state.activeStep + 1,
    }))
  }

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1,
    }))
  }

  handleReset = () => {
    this.setState({
      activeStep: 0,
    })
  }

  handleFieldChange = (key, value) => {
    this.setState({
      [key]: value,
    })
  }

  isStepComplete(step) {
    switch (step) {
      case 0:
        return ![
          this.state.firstName,
          this.state.lastName,
          this.state.email,
        ].some(item => item === '')
      case 1:
        return this.state.paymentMethod !== ''
      case 2:
        return true
      default:
        throw new Error('Unknown step')
    }
  }

  getStepContent(step, extraProps) {
    const props = {
      onChange: this.handleFieldChange,
      ...this.state,
      ...extraProps,
    }
    switch (step) {
      case 0:
        return <AddressForm {...props} />
      case 1:
        return <PaymentForm {...props} />
      case 2:
        return <Review {...props} />
      default:
        throw new Error('Unknown step')
    }
  }

  render() {
    const { classes } = this.props
    const { activeStep } = this.state
    const lastStep = activeStep === steps.length - 1
    return (
      <ShoppingCartContext.Consumer>
        {value => {
          const items = value.formatShoppingCart()
          return (
            <React.Fragment>
              <Dialog
                open={this.state.error}
                onClose={() => this.setState({ error: false })}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    There was a problem placing your order. Please check you
                    have entered all your details correctly, or{' '}
                    <a
                      href="mailto:sophia@spiralclassics.co.uk"
                      title="Email Spiral Classics"
                    >
                      email me
                    </a>{' '}
                    if the problem persists.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => this.setState({ error: false })}
                    color="secondary"
                  >
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
              <main className={classes.layout}>
                <div className={classes.paper}>
                  <Typography component="h1" variant="h4" align="center">
                    Checkout
                  </Typography>
                  <Stepper activeStep={activeStep} className={classes.stepper}>
                    {steps.map(label => (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                  <React.Fragment>
                    {activeStep === steps.length ? (
                      <React.Fragment>
                        <Typography variant="h5" gutterBottom>
                          Success!
                        </Typography>
                        <Typography variant="subtitle1">
                          Thank you for ordering from Spiral Classics. I will
                          check your order, and send you a pro forma invoice
                          that includes post &amp; packing costs.
                        </Typography>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        {this.getStepContent(activeStep, { items })}
                        <div className={classes.buttons}>
                          {activeStep !== 0 && (
                            <Button
                              onClick={this.handleBack}
                              className={classes.button}
                            >
                              Back
                            </Button>
                          )}
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                              const event = {
                                category: 'Checkout',
                                action: `Complete step - ${steps[activeStep]}`,
                              }
                              if (activeStep === 1) {
                                event.label = this.state.paymentMethod
                              }
                              if (lastStep) {
                                event.value = Number(
                                  calculateTotalPrice(
                                    items,
                                    this.state.recordsCleaned
                                  )
                                )
                              }
                              trackEvent(event)

                              if (lastStep) {
                                this.handleSubmit(
                                  items,
                                  value.clearShoppingCart
                                )
                              } else {
                                this.handleNext()
                              }
                            }}
                            className={classes.button}
                            disabled={!this.isStepComplete(activeStep)}
                          >
                            {lastStep ? 'Place order' : 'Next'}
                          </Button>
                        </div>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                </div>
              </main>
            </React.Fragment>
          )
        }}
      </ShoppingCartContext.Consumer>
    )
  }
}

Checkout.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Checkout)
