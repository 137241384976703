import { bold, italic, parentheses, filterAndJoin } from './text'

export function getComposer(record) {
  const contents = record.lpByRecordCode.contentsByRecordCode.nodes
  return (
    contents[0] &&
    filterAndJoin([contents[0].forename, contents[0].composer], ' ')
  )
}

export function getPiece(record) {
  const contents = record.lpByRecordCode.contentsByRecordCode.nodes
  return contents[0] && contents[0].titleOfPiece
}

export function getConductor(record, omitPrefix) {
  const contents = record.lpByRecordCode.contentsByRecordCode.nodes
  return (
    contents[0] &&
    filterAndJoin([contents[0].conductorForename, contents[0].conductor], ' ')
  )
}

export function getOrchestra(record) {
  const contents = record.lpByRecordCode.contentsByRecordCode.nodes
  return contents[0] && contents[0].orchestra
}

export function getEnsemble(record) {
  const contents = record.lpByRecordCode.contentsByRecordCode.nodes
  return contents[0] && contents[0].ensemble
}

export function getChoir(record) {
  const contents = record.lpByRecordCode.contentsByRecordCode.nodes
  return contents[0] && contents[0].choir
}

export function getInfo(record) {
  return filterAndJoin([
    record.boxEtc,
    record.infoOnSlBox,
    record.lpByRecordCode.sevenInch && '7"',
    record.lpByRecordCode.tenInch && '10"',
  ])
}

export function getArtists(contents, numArtists = 4) {
  const artists = []
  if (contents) {
    for (let i = 1; i <= numArtists; i++) {
      artists.push(
        filterAndJoin(
          [
            filterAndJoin([contents[`artist${i}`],
            contents[`aname${i}`]]),
            parentheses(contents[`instr${i}`]),
          ],
          ' '
        )
      )
    }
    return filterAndJoin(artists) + (contents[`artist${numArtists+1}`] ? '\u2026' : '')
  }
}

export function getRecordType(type) {
  switch (type) {
    case 'S':
      return 'Stereo'
    case 'M':
      return 'Mono'
    case 'D':
      return 'Digital stereo'
    case 'E':
      return 'Electronic stereo'
    case 'Q':
      return 'Stereo/quadrophonic'
    case 'R':
      return 'Digitally remastered'
    default:
      return ''
  }
}

export function getComments(record) {
  const contents = record.lpByRecordCode.contentsByRecordCode.nodes
  // Only include comment from contents if it only exists on the first item
  const contentsComment =
    contents.length > 1 && contents[1].comment ? null : contents[0].comment
  const monoComment =
    record.lpByRecordCode.monoOnly && 'no original stereo exists'
  return filterAndJoin([record.comment, contentsComment, monoComment])
}

export function formatArtists(contents, numArtists) {
  const artists = getArtists(contents, numArtists)
  return filterAndJoin([artists, contents.ensemble, contents.choir])
}

export function formatContents(contents, sortCode, highlight) {
  const composer =
    contents && filterAndJoin([contents.forename, contents.composer], ' ')
  const piece = contents && contents.titleOfPiece
  switch (sortCode) {
    case 'A': // Artist
      const artists = formatArtists(contents, 2) // Because artists are first in line, truncate to first two artists
      return filterAndJoin([
        highlight ? italic(artists) : artists,
        composer,
        piece,
      ])
    case 'C': // Composer
      return filterAndJoin([
        highlight ? italic(composer) : composer,
        piece,
        formatArtists(contents),
      ])
    default:
      return filterAndJoin([highlight ? composer : composer, piece, formatArtists(contents)])
  }
}

export function sortContents(record) {
  const contents = record.lpByRecordCode.contentsByRecordCode.nodes
  return contents.sort((a, b) => a.listOrder - b.listOrder)
}

export function formatRecordDetails(record, highlight) {
  const sortCode = record.lpByRecordCode.sortCode
  const title = record.lpByRecordCode.title
  const conductor = getConductor(record)
  const reIssueOf = record.lpByRecordCode.reIssueOf
  /* const sortUnder = record.lpByRecordCode.sortUnder */
  return filterAndJoin(
    [
      filterAndJoin(
        [
          /*highlight ? bold(sortUnder) : sortUnder,*/
          highlight ? bold(title) : title,
          sortCode === 'D' && (highlight ? italic(conductor) : conductor),
          filterAndJoin(
            sortContents(record).map(c =>
              formatContents(c, sortCode, highlight)
            )
          ),
          sortCode !== 'D' && conductor,
          reIssueOf && `reissue of ${reIssueOf}`,
        ],
        ' | '
      ),
    ],
    ' | '
  )
}
