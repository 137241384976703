import React from 'react'
import PropTypes from 'prop-types'
import * as types from '../types'
import { trackEvent } from '../helpers/tracking'
import { formatRecordDetails } from '../helpers/stock-list'
import naturalSort from '../helpers/natural-sort'
import stripHTML from '../helpers/strip-html'
import ShoppingCartContext from '../context/shopping-cart'
import { advancedExpandTableHOC } from './stock-table-hoc'
import ReactTable from 'react-table'
import RecordDetail from '../components/record-detail'
import InvisibleButton from '../components/invisible-button'
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket'
import ClearIcon from '@material-ui/icons/Clear'
import 'react-table/react-table.css'

const Table = advancedExpandTableHOC(ReactTable)

function buildExpandableCell(props, value, style = {}) {
  const {
    columnProps: {
      rest: { toggleRowSubComponent },
    },
    ...rest
  } = props
  return (
    <div
      title={stripHTML(value)}
      onClick={e => toggleRowSubComponent({ ...rest }, e)}
      style={{
        cursor: 'pointer',
        padding: '7px 0 7px 5px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        ...style,
      }}
      dangerouslySetInnerHTML={{ __html: value }}
    />
  )
}

function buildColumns({
  shoppingCart,
  addItemToShoppingCart,
  removeItemFromShoppingCart,
}) {
  return [
    {
      id: 'addToCart',
      Header: 'Buy',
      maxWidth: 40,
      sortable: false,
      resizable: false,
      Cell: row => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {shoppingCart.has(row.original.acno) ? (
            <InvisibleButton
              title="Remove from shopping bag"
              aria-label="Remove from shopping bag"
              onClick={() => {
                trackEvent({
                  category: 'Stock',
                  action: 'Remove from bag - icon',
                  label: row.original.acno,
                  value: Number(row.original.price),
                })
                removeItemFromShoppingCart(row.original)
              }}
            >
              <ClearIcon color="secondary" />
            </InvisibleButton>
          ) : (
            <InvisibleButton
              title="Add to shopping bag"
              aria-label="Add to shopping bag"
              onClick={() => {
                trackEvent({
                  category: 'Stock',
                  action: 'Add to bag - icon',
                  label: row.original.acno,
                  value: Number(row.original.price),
                })
                addItemToShoppingCart(row.original)
              }}
            >
              <ShoppingBasketIcon color="secondary" />
            </InvisibleButton>
          )}
        </div>
      ),
    },
    {
      Header: 'Record code',
      accessor: 'recordCode',
      maxWidth: 120,
      Cell: row => buildExpandableCell(row, row.value),
      style: { padding: '0' },
      resizable: false,
    },
    {
      Header: 'Cond',
      accessor: 'condition',
      maxWidth: 100,
      minWidth: 40,
      Cell: row => buildExpandableCell(row, row.value ? row.value.split(',')[0] : ''),
      headerClassName: 'header-cell',
      style: { padding: '0' },
      resizable: false,
    },
    {
      id: 'details',
      Header: 'Details',
      accessor: d => formatRecordDetails(d, true),
      Cell: row => buildExpandableCell(row, row.value),
      style: { padding: '0' },
      resizable: false,
      minWidth: 400,
    },
    {
      id: 'label',
      Header: 'Label',
      accessor: 'label',
      Cell: row => buildExpandableCell(row, row.value),
      style: { padding: '0' },
      resizable: false,
      maxWidth: 120,
    },
    {
      id: 'pressing',
      Header: 'Notes',
      accessor: 'pressing',
      Cell: row => buildExpandableCell(row, row.value),
      style: { padding: '0' },
      resizable: false,
      maxWidth: 120,
    },
    {
      Header: 'Price (£)',
      accessor: 'price',
      sortMethod: (a, b) => (Number(a) > Number(b) ? 1 : -1),
      maxWidth: 95,
      Cell: row =>
        buildExpandableCell(row, row.value, {
          textAlign: 'right',
          paddingRight: '5px',
        }),
      style: { padding: '0' },
      resizable: false,
    },
  ]
}

const StockTable = ({ data = [], ...rest }) => (
  <ShoppingCartContext.Consumer>
    {value => (
      <Table
        data={data}
        defaultSortMethod={naturalSort}
        columns={buildColumns(value)}
        className="-striped -highlight stock-list__table"
        SubComponent={({ original }) => <RecordDetail record={original} />}
        style={{
          backgroundColor: '#fff',
        }}
        getTheadThProps={() => ({
          style: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
        })}
        getTrProps={(state, rowInfo, column) => ({
          style: {
            display: 'flex',
            alignItems: 'center',
          },
        })}
        showPaginationTop
        showPaginationBottom
        rowsText="records per page"
        noDataText="No records found"
        minRows={5}
        showPageJump={false}
        pageSizeOptions={[50, 100, 250, 500]}
        onExpandedChange={rowInfo => {
          if (rowInfo.isExpanded) {
            trackEvent({
              category: 'Stock',
              action: 'View details',
              label: rowInfo.original.acno,
              value: Number(rowInfo.original.price),
            })
          }
        }}
        {...rest}
      />
    )}
  </ShoppingCartContext.Consumer>
)

StockTable.propTypes = {
  data: PropTypes.arrayOf(types.record.isRequired),
}

export default StockTable
