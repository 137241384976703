import React from 'react'
import PropTypes from 'prop-types'
import InvisibleButton from './invisible-button'
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket'

const ShoppingCartButton = ({ onClick, value }) => (
  <InvisibleButton
    onClick={e => {
      e.preventDefault()
      onClick()
    }}
    style={{
      display: 'flex',
      alignItems: 'flex-end',
      letterSpacing: 0.2,
      color: '#fff',
    }}
  >
    <ShoppingBasketIcon />
    &nbsp;{`Shopping bag (${value})`}
  </InvisibleButton>
)

ShoppingCartButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  value: PropTypes.number,
}

ShoppingCartButton.defaultProps = {
  value: 0,
}

export default ShoppingCartButton
