import React from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import InvisibleButton from '../components/invisible-button'
import { Link } from 'gatsby'

class Navigation extends React.Component {
  state = {
    anchorEl: null,
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  render() {
    const { anchorEl } = this.state
    const { items, collapse } = this.props
    return (
      <nav
        style={{
          backgroundColor: '#385D5D',
          boxShadow:
            '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
        }}
      >
        <ul
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            maxWidth: !collapse && 900,
            margin: '0 auto',
            letterSpacing: 0.5,
          }}
        >
          {collapse ? (
            <React.Fragment>
              <li
                style={{
                  padding: '0 25px',
                  margin: 0,
                  listStyle: 'none',
                  lineHeight: '40px',
                  color: '#fff',
                  width: '100%',
                }}
              >
                <InvisibleButton
                  aria-owns={anchorEl ? 'nav-menu' : undefined}
                  aria-haspopup="true"
                  onClick={this.handleClick}
                >
                  Menu
                </InvisibleButton>
              </li>
              <Menu
                id="nav-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={this.handleClose}
              >
                {items.map(item => (
                  <Link to={item.link} key={item.link}>
                    <MenuItem onClick={this.handleClose}>{item.name}</MenuItem>
                  </Link>
                ))}
              </Menu>
            </React.Fragment>
          ) : (
            items.map(item => {
              const active =
                typeof window !== 'undefined' &&
                window.location.pathname.includes(item.link)
              return (
                <Link to={item.link} key={item.link}>
                  <li
                    style={{
                      padding: '0 25px',
                      margin: 0,
                      listStyle: 'none',
                      lineHeight: '40px',
                      fontWeight: active ? 700 : 'inherit',
                      backgroundColor: active
                        ? 'rgba(0, 0, 0, 0.3)'
                        : 'transparent',
                      color: '#fff',
                    }}
                  >
                    {item.name}
                  </li>
                </Link>
              )
            })
          )}
        </ul>
      </nav>
    )
  }
}

export default Navigation
