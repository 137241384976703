import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { trackEvent } from '../helpers/tracking'
import matchSorter from 'match-sorter'
import debounce from 'lodash.debounce'
import withStyles from '@material-ui/core/styles/withStyles'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'
import Layout from '../components/layout'
import SEO from '../components/seo'
import SearchBar from 'material-ui-search-bar'
import StockTable from '../components/stock-table'
import StockListHelp from '../components/stock-list-help'
import Pagination from '../components/pagination'
import FilterDropdown from '../components/filter-dropdown'
import queryString from 'query-string'

const styles = {
  iconButton: {
    display: 'none',
  },
  searchContainer: {
    display: 'flex',
    width: '100%',
  },
}

const DEFAULT_PAGE_SIZE = 100

class StockList extends React.Component {
  static generateNodeSearchKeys() {
    return [0, 1, 2, 3]
      .map(i => [
        `lpByRecordCode.contentsByRecordCode.nodes.${i}.aname1`,
        `lpByRecordCode.contentsByRecordCode.nodes.${i}.artist1`,
        `lpByRecordCode.contentsByRecordCode.nodes.${i}.instr1`,
        `lpByRecordCode.contentsByRecordCode.nodes.${i}.aname2`,
        `lpByRecordCode.contentsByRecordCode.nodes.${i}.artist2`,
        `lpByRecordCode.contentsByRecordCode.nodes.${i}.instr2`,
        `lpByRecordCode.contentsByRecordCode.nodes.${i}.aname3`,
        `lpByRecordCode.contentsByRecordCode.nodes.${i}.artist3`,
        `lpByRecordCode.contentsByRecordCode.nodes.${i}.instr3`,
        `lpByRecordCode.contentsByRecordCode.nodes.${i}.aname4`,
        `lpByRecordCode.contentsByRecordCode.nodes.${i}.artist4`,
        `lpByRecordCode.contentsByRecordCode.nodes.${i}.instr4`,
        `lpByRecordCode.contentsByRecordCode.nodes.${i}.composer`,
        `lpByRecordCode.contentsByRecordCode.nodes.${i}.forename`,
        `lpByRecordCode.contentsByRecordCode.nodes.${i}.conductor`,
        `lpByRecordCode.contentsByRecordCode.nodes.${i}.conductorForename`,
        `lpByRecordCode.contentsByRecordCode.nodes.${i}.titleOfPiece`,
        `lpByRecordCode.contentsByRecordCode.nodes.${i}.orchestra`,
        `lpByRecordCode.contentsByRecordCode.nodes.${i}.ensemble`,
        `lpByRecordCode.contentsByRecordCode.nodes.${i}.choir`,
      ])
      .reduce((x, y) => x.concat(y))
  }

  static getMainSearchKeys() {
    return [
      'label',
      'pressing',
      'lpByRecordCode.title',
      'lpByRecordCode.company',
      ...StockList.generateNodeSearchKeys(),
    ]
  }

  static getRecordCodeSearchKeys() {
    return [
      {
        threshold: matchSorter.rankings.MATCHES,
        key: 'recordCode',
      },
      {
        threshold: matchSorter.rankings.MATCHES,
        key: 'lpByRecordCode.reIssueOf',
      },
    ]
  }

  static getClassifcationSearchKeys() {
    return [
      {
        threshold: matchSorter.rankings.CASE_SENSITIVE_EQUAL,
        key: 'lpByRecordCode.classification',
      },
    ]
  }

  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      pageSize: DEFAULT_PAGE_SIZE,
      data: props.data,
      recordCodeQuery: '',
      mainQuery: '',
      shouldCollapse: false,
      showHelp: false,
      filter: '',
    }
    this.handlePageChange = this.handlePageChange.bind(this)
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this)
  }

  componentDidMount() {
    this.focusMainSearch()
    const params = queryString.parse(this.props.location.search)
    const page = params.page ? params.page - 1 : 0
    if (params.recordCode || params.search || params.filter) {
      // Page will get updated in updateQuery
      if (params.recordCode) {
        this.updateQuery(params.recordCode, 'recordCodeQuery', page)
      }
      if (params.search) {
        this.updateQuery(params.search, 'mainQuery', page)
      }
      if (params.filter) {
        this.updateQuery(params.filter, 'filter', page)
      }
    } else {
      // Set page manually
      if (page) {
        this.setState({ page })
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      const prevSearchParams = new URLSearchParams(prevProps.location.search); 
      const searchParams = new URLSearchParams(this.props.location.search); 
      if (searchParams.has("page") || (prevSearchParams.has("page") && !searchParams.has("page"))) {
        // Triggered by page change
        const currentPage = Number(searchParams.get("page"))
        const page = currentPage ? currentPage - 1 : 0
        this.setState({ page })
      }
    }
  }

 focusMainSearch() {
    const mainSearch = document.querySelectorAll('input[type=text]')[1]
    if (mainSearch) {
      mainSearch.focus()
    }
  }

  search(input, data, searchKeys) {
    if (input === '') {
      return data
    } else {
      const trimmed = input.trim()
      let first, rest
      if (/^("|')/.test(trimmed)) {
        const quote = trimmed.slice(0, 1)
        const afterQuote = trimmed.slice(1)
        const end = afterQuote.indexOf(quote)
        first = afterQuote.slice(0, end)
        rest = afterQuote
          .slice(end + 1)
          .trim()
          .split(' ')
      } else {
        ;[first, ...rest] = trimmed.split(' ')
      }
      return this.search(
        rest.join(' '),
        matchSorter(data, first, {
          keys: searchKeys,
          threshold: matchSorter.rankings.CONTAINS,
        }),
        searchKeys
      )
    }
  }

  classificationSearch(input, data, searchKeys) {
    if (input === '') {
      return data
    } else {
      return matchSorter(data, input, {
        keys: searchKeys,
        threshold: matchSorter.rankings.CONTAINS,
      })
    }
  }

  trackSearch = debounce(
    (query, key) =>
      query &&
      trackEvent({
        category: 'Stock',
        action: `Search - ${key}`,
        label: query,
      }),
    2000
  )

  updateQuery(query, key, page = 0) {
    this.trackSearch(query, key)
    if (this.state.page !== 0) {
      this.handlePageChange(0)
    }
    this.setState({ [key]: query, page, shouldCollapse: true }, () => {
      const filteredByClassification = this.classificationSearch(
        this.state.filter,
        this.props.data,
        StockList.getClassifcationSearchKeys()
      )
      const filteredByRecordCode = this.search(
        this.state.recordCodeQuery,
        filteredByClassification,
        StockList.getRecordCodeSearchKeys()
      )
      const filteredByAll = this.search(
        this.state.mainQuery,
        filteredByRecordCode,
        StockList.getMainSearchKeys()
      )
      this.setState({ data: filteredByAll, shouldCollapse: false })
    })
  }

  handleSearch = debounce(this.updateQuery, 200)

  resetSearch(key) {
    if (key) {
      // Single field cleared
      this.updateQuery('', key)
    } else {
      // Reset button clicked
      this.setState(
        {
          data: this.props.data,
          recordCodeQuery: '',
          mainQuery: '',
          filter: '',
          page: 0,
          shouldCollapse: true,
        },
        () => this.setState({ shouldCollapse: false })
      )
      trackEvent({ category: 'Stock', action: 'Reset search' })
      this.props.navigate('/stock-list')
    }
  }

  handlePageChange(index) {
    const page = index + 1
    const params = { ...queryString.parse(this.props.location.search) }
    if (page === 1) {
      delete params.page
    } else {
      params.page = page
    }
    const strParams = Object.keys(params).length
      ? `?${queryString.stringify(params)}`
      : ''
    this.props.navigate(`/stock-list${strParams}`)
    trackEvent({ category: 'Stock', action: 'Paginate', label: page })
  }

  handlePageSizeChange(pageSize) {
    this.setState({ pageSize })
    trackEvent({
      category: 'Stock',
      action: 'Change page size',
      label: pageSize,
    })
  }

  render() {
    const paginationProps = {
      page: this.state.page + 1,
      pages: Math.ceil(this.state.data.length / this.state.pageSize),
      onSelect: (page, event) => {
        event.preventDefault()
        this.handlePageChange(page - 1)
      },
    }
    const searchBarStyles = {
      boxShadow:
        '0px 1px 5px 0px rgba(0,0,0,0.3), 0px 2px 2px 0px rgba(0,0,0,0.3), 0px 3px 1px -2px rgba(0,0,0,0.3)',
      borderRadius: '25px',
    }
    return (
      <Layout>
        <SEO title="Stock list" />
        <Grid container spacing={24} style={{ marginBottom: 20 }}>
          <Grid item xs={12} md={3} lg={2}>
            <p style={{ marginBottom: 5 }}>Search by record code:</p>
            <SearchBar
              value={this.state.recordCodeQuery}
              onChange={query => this.handleSearch(query, 'recordCodeQuery')}
              onCancelSearch={() => this.resetSearch('recordCodeQuery')}
              cancelOnEscape
              placeholder="Record code"
              classes={{
                iconButton: this.props.classes.iconButton,
                searchContainer: this.props.classes.searchContainer,
              }}
              style={searchBarStyles}
            />
          </Grid>
          <Grid item xs={12} md={7} lg={6}>
            <p style={{ marginBottom: 5 }}>Search by other record details:</p>
            <SearchBar
              value={this.state.mainQuery}
              onChange={query => this.handleSearch(query, 'mainQuery')}
              onCancelSearch={() => this.resetSearch('mainQuery')}
              cancelOnEscape
              searchIcon={<SearchIcon style={{ opacity: 1, color: 'black' }} />}
              placeholder={
                'Composer, conductor, performer, piece of music, etc.'
              }
              classes={{
                searchContainer: this.props.classes.searchContainer,
              }}
              style={searchBarStyles}
            />
          </Grid>
          <Grid
            item
            xs={6}
            sm={4}
            lg={2}
            style={{ display: 'flex', alignItems: 'flex-end' }}
          >
            <FilterDropdown
              filter={this.state.filter}
              handleFilter={value => this.updateQuery(value, 'filter')}
            />
          </Grid>
          <Grid
            item
            xs={6}
            sm={2}
            lg={2}
            style={{ display: 'flex', alignItems: 'flex-end' }}
          >
            <div style={{ display: 'flex', height: 48 }}>
              <Button
                onClick={() => this.resetSearch()}
                color="secondary"
                variant={'outlined'}
              >
                Reset
              </Button>
              &nbsp;
              <Button
                onClick={() => {
                  trackEvent({ category: 'Stock', action: 'View help' })
                  this.setState({ showHelp: true })
                }}
                aria-label="Help"
                color="secondary"
                title="Help"
                variant={'outlined'}
              >
                Help
              </Button>
            </div>
            <Dialog
              open={this.state.showHelp}
              onClose={() => this.setState({ showHelp: false })}
              fullScreen={this.props.fullScreen}
            >
              <IconButton
                aria-label="Close"
                style={{
                  position: 'absolute',
                  right: 10,
                  top: 10,
                }}
                onClick={() => this.setState({ showHelp: false })}
              >
                <CloseIcon />
              </IconButton>
              <StockListHelp />
            </Dialog>
          </Grid>
        </Grid>
        <StockTable
          data={this.state.data}
          page={this.state.page}
          onPageChange={this.handlePageChange}
          pageSize={this.state.pageSize}
          onPageSizeChange={this.handlePageSizeChange}
          defaultPageSize={DEFAULT_PAGE_SIZE}
          shouldCollapse={this.state.shouldCollapse}
        />
        <Pagination {...paginationProps} />
      </Layout>
    )
  }
}

const WrappedStockList = withMobileDialog()(withStyles(styles)(StockList))

export default props => (
  <StaticQuery
    query={graphql`
      query {
        postgres {
          allStocks(orderBy: RECORD_CODE_ASC) {
            nodes {
              acno
              recordCode
              condition
              boxEtc
              infoOnSlBox
              price
              comment
              label
              pressing
              lpByRecordCode {
                company
                title
                noOfRecords
                asList
                reIssueOf
                dateOfIssue
                audiophile
                recommended
                sortCode
                type
                monoOnly
                sevenInch
                tenInch
                classification
                contentsByRecordCode {
                  nodes {
                    composer
                    forename
                    opusNo
                    titleOfPiece
                    conductor
                    conductorForename
                    orchestra
                    ensemble
                    comment
                    additionalContents
                    artist1
                    aname1
                    instr1
                    artist2
                    aname2
                    instr2
                    artist3
                    aname3
                    instr3
                    artist4
                    aname4
                    choir
                    dateRecorded
                    originalInstr
                    liveRecording
                    listOrder
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <WrappedStockList {...props} data={data.postgres.allStocks.nodes} />
    )}
  />
)
