import React from 'react'
import Paginator from 'react-pagify'
import segmentize from 'segmentize'
import '../styles/overrides.css'

const Pagination = ({ page, pages, onSelect }) => (
  <div className="pagination__container">
    Page:
    <Paginator.Context
      className="pagination"
      tags={{
        link: {
          tag: 'a',
          props: {
            href: '',
          },
        },
      }}
      segments={segmentize({
        page,
        pages,
        beginPages: 1,
        endPages: 1,
        sidePages: 2,
      })}
      onSelect={onSelect}
    >
      <Paginator.Segment field="beginPages" className="pagination__segment" />
      <Paginator.Ellipsis
        previousField="beginPages"
        nextField="previousPages"
      />
      <Paginator.Segment
        field="previousPages"
        className="pagination__segment"
      />
      <Paginator.Segment
        field="centerPage"
        className="pagination__segment pagination__segment--active"
      />
      <Paginator.Segment field="nextPages" className="pagination__segment" />
      <Paginator.Ellipsis previousField="nextPages" nextField="endPages" />
      <Paginator.Segment field="endPages" className="pagination__segment" />
    </Paginator.Context>
  </div>
)

export default Pagination
