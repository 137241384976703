import PropTypes from 'prop-types'

export const record = PropTypes.shape({
  acno: PropTypes.string.isRequired,
  recordCode: PropTypes.string.isRequired,
  condition: PropTypes.string,
  boxEtc: PropTypes.string,
  infoOnSlBox: PropTypes.string,
  price: PropTypes.string.isRequired,
  comment: PropTypes.string,
  label: PropTypes.string,
  pressing: PropTypes.string,
  lpByRecordCode: PropTypes.shape({
    company: PropTypes.string,
    title: PropTypes.string,
    noOfRecords: PropTypes.string,
    asList: PropTypes.bool.isRequired,
    reIssueOf: PropTypes.string,
    datOfIssue: PropTypes.string,
    audiophile: PropTypes.bool.isRequired,
    recommended: PropTypes.bool.isRequired,
    sortCode: PropTypes.oneOf(['A', 'C', 'D']),
    type: PropTypes.oneOf(['S', 'M', 'D', 'E', 'Q', 'R']),
    monoOnly: PropTypes.bool.isRequired,
    sevenInch: PropTypes.bool.isRequired,
    tenInch: PropTypes.bool.isRequired,
    contentsByRecordCode: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          composer: PropTypes.string,
          forename: PropTypes.string,
          opusNo: PropTypes.string,
          titleOfPiece: PropTypes.string,
          conductor: PropTypes.string,
          conductorForename: PropTypes.string,
          orchestra: PropTypes.string,
          ensemble: PropTypes.string,
          comment: PropTypes.string,
          additionalContents: PropTypes.string,
          artist1: PropTypes.string,
          aname1: PropTypes.string,
          instr1: PropTypes.string,
          artist2: PropTypes.string,
          aname2: PropTypes.string,
          instr2: PropTypes.string,
          artist3: PropTypes.string,
          aname3: PropTypes.string,
          instr3: PropTypes.string,
          artist4: PropTypes.string,
          aname4: PropTypes.string,
          choir: PropTypes.string,
          dateRecorded: PropTypes.string,
          originalInstr: PropTypes.bool,
          liveRecording: PropTypes.bool,
          listOrder: PropTypes.number,
        })
      ),
    }),
  }),
})
