import React from 'react'
import { trackEvent } from '../helpers/tracking'
import axios from 'axios'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

const styles = theme => ({
  root: {
    color: theme.palette.primary.contrastText,
  },
  rootDark: {
    color: theme.palette.primary.dark,
  },
  label: {
    color: theme.palette.primary.contrastText,
    '&$focused': {
      color: theme.palette.primary.contrastText,
    },
  },
  labelDark: {
    color: theme.palette.primary.dark,
    '&$focused': {
      color: theme.palette.primary.dark,
    },
  },
  focused: {},
  underline: {
    '&:before': {
      borderBottomColor: `${theme.palette.primary.light} !important`,
    },
    '&:after': {
      borderBottomColor: `${theme.palette.primary.light} !important`,
    },
  },
  button: {
    '&:disabled': {
      color: theme.palette.primary.light,
    },
  },
  close: {
    padding: theme.spacing.unit / 2,
  },
})

class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      message: '',
      success: false,
      error: false,
    }
    this.submit = this.submit.bind(this)
  }

  submit() {
    trackEvent({ category: 'Engagement', action: 'Submit contact form' })
    const form = document.querySelector(`form[name=${this.props.formName}]`)
    const data = new FormData(form)
    axios
      .post('/cgi-bin/FormMail.pl', data)
      .then(() =>
        this.setState({
          success: true,
          error: false,
          name: '',
          email: '',
          message: '',
        })
      )
      .catch(() => this.setState({ error: true }))
  }

  render() {
    const { classes, background, formName } = this.props
    const { name, email, message, success, error } = this.state
    const disabled = !name || !email || !message
    let formStatus = ''
    if (success) formStatus = 'Success! Message sent'
    if (error) formStatus = 'Something went wrong. Please try again later.'
    return (
      <form name={formName}>
        <input type="hidden" name="subject" value="Message" />
        <input type="hidden" name="recipient" value="first" />
        <input type="hidden" name="required" value="realname,email,message" />
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <TextField
              required
              id="realname"
              name="realname"
              label="Name"
              fullWidth
              value={name}
              onChange={e => this.setState({ name: e.target.value })}
              className={classes.root}
              InputProps={{
                classes: {
                  root: background === 'light' ? classes.rootDark : classes.root,
                  underline: classes.underline,
                  focused: classes.focused,
                },
              }}
              InputLabelProps={{
                classes: {
                  root: background === 'light' ? classes.labelDark : classes.label,
                  focused: classes.focused,
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="email"
              name="email"
              label="Email"
              fullWidth
              value={email}
              onChange={e => this.setState({ email: e.target.value })}
              className={classes.root}
              InputProps={{
                classes: {
                  root: background === 'light' ? classes.rootDark : classes.root,
                  underline: classes.underline,
                  focused: classes.focused,
                },
              }}
              InputLabelProps={{
                classes: {
                  root: background === 'light' ? classes.labelDark : classes.label,
                  focused: classes.focused,
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="message"
              name="message"
              label="Message"
              fullWidth
              value={message}
              multiline
              onChange={e => this.setState({ message: e.target.value })}
              className={classes.root}
              InputProps={{
                classes: {
                  root: background === 'light' ? classes.rootDark : classes.root,
                  underline: classes.underline,
                  focused: classes.focused,
                },
              }}
              InputLabelProps={{
                classes: {
                  root: background === 'light' ? classes.labelDark : classes.label,
                  focused: classes.focused,
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="secondary"
              onClick={this.submit}
              disabled={disabled}
              className={classes.button}
            >
              Submit
            </Button>
            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              open={success || error}
              onClose={() => this.setState({ success: false, error: false })}
              ContentProps={{
                'aria-describedby': 'message-id',
              }}
              message={<span id="message-id">{formStatus}</span>}
              action={[
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  className={classes.close}
                  onClick={() =>
                    this.setState({ success: false, error: false })
                  }
                >
                  <CloseIcon />
                </IconButton>,
              ]}
            />
          </Grid>
        </Grid>
      </form>
    )
  }
}

export default withStyles(styles)(ContactForm)
