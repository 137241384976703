import axios from 'axios'
import { filterAndJoin } from './text'
import { formatRecordDetails } from './stock-list';
import paymentMethods from './payment'

const CLEANING_FEE = 4

export function formatCleaningFee() {
  return CLEANING_FEE.toFixed(2)
}

function getDescendantProp(obj, desc) {
  let result = { ...obj }
  const arr = desc.split('.')
  while (arr.length) {
    result = result[arr.shift()]
  }
  return result
}

function calculateTotal(items, propPath) {
  let total
  switch (items.length) {
    case 0:
      total = 0
      break
    case 1:
      total = Number(getDescendantProp(items[0], propPath))
      break
    default:
      total = items.reduce(
        (acc, item) => acc + Number(getDescendantProp(item, propPath)),
        0
      )
      break
  }
  return total
}

export function calculateTotalLps(items) {
  return calculateTotal(items, 'lpByRecordCode.noOfRecords')
}

export function calculateCleaningTotal(items) {
  return (CLEANING_FEE * calculateTotalLps(items)).toFixed(2)
}

export function calculateTotalPrice(items, withCleaning) {
  const total = calculateTotal(items, 'price')
  const cleaningTotal = withCleaning ? Number(calculateCleaningTotal(items)) : 0
  return (total + cleaningTotal).toFixed(2)
}

export function formatItemsForReview(items) {
  return items.map(item => ({
    name: item.recordCode,
    desc: formatRecordDetails(item),
    price: `£${item.price}`,
  }))
}

export function formatItemsForOrder(items) {
  return items
    .map(item =>
      filterAndJoin([
        `Stock no: ${item.acno}`,
        `Record code: ${item.recordCode}`,
        `Details: ${formatRecordDetails(item)}`,
        `Price: ${item.price}`,
      ])
    )
    .join('\n')
}

export function formatAddress(values) {
  return filterAndJoin([
    values.address1,
    values.address2,
    values.city,
    values.state,
    values.zip,
    values.country,
  ])
}

export function submitOrder(items, values) {
  const data = new FormData()
  data.append('subject', 'Order')
  data.append('recipient', 'first')
  data.append('required', 'realname,email,order')
  data.append('realname', `${values.firstName} ${values.lastName}`)
  data.append('email', values.email)
  data.append('order', formatItemsForOrder(items))
  data.append('cleaning', values.recordsCleaned ? 'yes' : 'no')
  data.append('payment', paymentMethods[values.paymentMethod])
  data.append('instructions', values.instructions)
  data.append('mailing list', values.addToMailingList ? 'yes' : 'no')
  data.append('address', formatAddress(values))
  data.append('postcode', values.zip)
  data.append('country', values.country)
  data.append('tel', values.phone)
  return axios.post('/cgi-bin/FormMail.pl', data)
}
