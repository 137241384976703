import React from 'react'
import * as types from '../types'
import * as helpers from '../helpers/stock-list'
import { trackEvent } from '../helpers/tracking'
import ShoppingCartContext from '../context/shopping-cart'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import DoneIcon from '@material-ui/icons/Done'
import AudiophileIcon from '@material-ui/icons/MusicNote'
import RecommendedIcon from '@material-ui/icons/Star'
import RecordDetailItem from './record-detail-item'

const RecordDetail = ({ record }) => {
  const title = record.lpByRecordCode.title
  const composer = helpers.getComposer(record)
  const piece = helpers.getPiece(record)
  const conductor = helpers.getConductor(record, true)
  const orchestra = helpers.getOrchestra(record)
  const ensemble = helpers.getEnsemble(record)
  const choir = helpers.getChoir(record)
  const contents = helpers.sortContents(record)
  const date = record.lpByRecordCode.dateOfIssue
  const company = record.lpByRecordCode.company
  const numRecords = record.lpByRecordCode.noOfRecords
  const recordType = helpers.getRecordType(record.lpByRecordCode.type)
  const artists = helpers.getArtists(contents[0])
  const additionalContents = contents[0].additionalContents
  const info = helpers.getInfo(record)
  const audiophile = record.lpByRecordCode.audiophile
  const asList = record.lpByRecordCode.asList
  const recommended = record.lpByRecordCode.recommended
  const comments = helpers.getComments(record)
  const commentsIncluded = comments && comments.includes(contents[0].comment)
  const reIssueOf = record.lpByRecordCode.reIssueOf
  const classification = record.lpByRecordCode.classification

  const chipStyle = {
    display: 'inline-block',
    padding: '0 10px 16px 0',
  }

  return (
    <ShoppingCartContext.Consumer>
      {value => (
        <div style={{ padding: '20px' }}>
          {audiophile && (
            <div style={chipStyle}>
              <Chip label="Audiophile" icon={<AudiophileIcon />} />
            </div>
          )}
          {asList && (
            <div style={chipStyle}>
              <Chip label="On the Absolute Sound Super Disc list" />
            </div>
          )}
          {recommended && (
            <div style={chipStyle}>
              <Chip label="Recommended" icon={<RecommendedIcon />} />
            </div>
          )}
          <RecordDetailItem title="Record code" content={record.recordCode} />
          {reIssueOf && (
            <RecordDetailItem title="Reissue of" content={reIssueOf} />
          )}
          {title && <RecordDetailItem title="Title" content={title} />}
          {contents.length > 1 ? (
            <RecordDetailItem title="Contents">
              <ul style={{ marginTop: 5, marginBottom: 5 }}>
                {contents.map((c, i) => (
                  <li style={{ marginBottom: 5 }} key={i}>
                    {helpers.formatContents(c, record.lpByRecordCode.sortCode)}
                    {c.comment && !commentsIncluded && (
                      <ul style={{ marginTop: 5, marginBottom: 5 }}>
                        <li>{c.comment}</li>
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            </RecordDetailItem>
          ) : (
            <>
              {composer && (
                <RecordDetailItem title="Composer" content={composer} />
              )}
              {piece && <RecordDetailItem title="Piece" content={piece} />}
              {artists && (
                <RecordDetailItem title="Performers" content={artists} />
              )}
            </>
          )}
          {conductor && (
            <RecordDetailItem title="Conductor" content={conductor} />
          )}
          {orchestra && (
            <RecordDetailItem title="Orchestra" content={orchestra} />
          )}
          {ensemble && <RecordDetailItem title="Ensemble" content={ensemble} />}
          {choir && <RecordDetailItem title="Choir" content={choir} />}
          {company && <RecordDetailItem title="Company" content={company} />}
          {numRecords > 1 && (
            <RecordDetailItem title="Number of records" content={numRecords} />
          )}
          {date && <RecordDetailItem title="Date issued" content={date} />}
          {additionalContents && (
            <RecordDetailItem
              title="Additional contents"
              content={additionalContents}
            />
          )}
          {recordType && (
            <RecordDetailItem title="Record type" content={recordType} />
          )}
          {record.label && (
            <RecordDetailItem title="Label" content={record.label} />
          )}
          {record.pressing && (
            <RecordDetailItem title="Notes" content={record.pressing} />
          )}
          {record.condition && (
            <RecordDetailItem title="Condition" content={record.condition} />
          )}
          {comments && <RecordDetailItem title="Comments" content={comments} />}
          {info && <RecordDetailItem title="Additional info" content={info} />}
          {classification && (
            <RecordDetailItem title="Category" content={classification} />
          )}
          <p style={{ marginTop: 16 }}>£{record.price}</p>
          <div style={{ marginTop: 16 }}>
            {value.shoppingCart.has(record.acno) ? (
              <>
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    color: '#59a98d',
                    marginBottom: 10,
                  }}
                >
                  <DoneIcon />
                  &nbsp;Added to bag
                </span>
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    trackEvent({
                      category: 'Stock',
                      action: 'Remove from bag - button',
                      label: record.acno,
                      value: Number(record.price),
                    })
                    value.removeItemFromShoppingCart(record)
                  }}
                >
                  Remove
                </Button>
              </>
            ) : (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  trackEvent({
                    category: 'Stock',
                    action: 'Add to bag - button',
                    label: record.acno,
                    value: Number(record.price),
                  })
                  value.addItemToShoppingCart(record)
                }}
              >
                Add to bag
              </Button>
            )}
          </div>
        </div>
      )}
    </ShoppingCartContext.Consumer>
  )
}

RecordDetail.propTypes = {
  record: types.record.isRequired,
}

export default RecordDetail
