import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'

const FilterDropdown = ({ classifications, filter, handleFilter }) => {
  return (
    <FormControl fullWidth>
      <InputLabel htmlFor="filter">Filter by category</InputLabel>
      <Select
        value={filter}
        onChange={event => handleFilter(event.target.value)}
        inputProps={{
          name: 'filter',
          id: 'filter',
        }}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {classifications.map(({ classification }) => (
          <MenuItem key={classification} value={classification}>
            {classification}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        postgres {
          allClassificationsLookUpSList {
            classification
          }
        }
      }
    `}
    render={data => (
      <FilterDropdown
        {...props}
        classifications={data.postgres.allClassificationsLookUpSList}
      />
    )}
  />
)
