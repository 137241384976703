import React from 'react'
import paymentMethods from '../helpers/payment'
import Typography from '@material-ui/core/Typography'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'

function PaymentForm({ onChange, paymentMethod }) {
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Payment method
      </Typography>
      <RadioGroup
        aria-label="Payment method"
        name="paymentMethod"
        value={paymentMethod}
        onChange={(e, val) => onChange('paymentMethod', val)}
      >
        <FormControlLabel
          value="card"
          control={<Radio />}
          label={paymentMethods.card}
        />
        <FormControlLabel
          value="amex"
          control={<Radio />}
          label={paymentMethods.amex}
        />
        <FormControlLabel
          value="cheque"
          control={<Radio />}
          label={paymentMethods.cheque}
        />
        <FormControlLabel
          value="bankTransfer"
          control={<Radio />}
          label={paymentMethods.bankTransfer}
        />
        <FormControlLabel
          value="other"
          control={<Radio />}
          label={paymentMethods.other}
        />
      </RadioGroup>
      <br />
      <Typography gutterBottom>
        I shall email you an invoice when I have checked your order.{' '}
        <a href="/payment-methods" target="_blank">
          More about payment methods.
        </a>
      </Typography>
    </React.Fragment>
  )
}

export default PaymentForm
