import React from 'react'
import PropTypes from 'prop-types'
import { trackEvent } from '../helpers/tracking'
import { formatRecordDetails } from '../helpers/stock-list'
import { calculateTotalPrice } from '../helpers/shopping-cart'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import ArrowForward from '@material-ui/icons/ArrowForward'
import ClearIcon from '@material-ui/icons/Clear'
import Dialog from '@material-ui/core/Dialog'
import Checkout from './checkout'

class ShoppingCart extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      checkoutInProgress: false,
    }
  }

  render() {
    const { items, open, onClose, onRemoveItem, fullScreen } = this.props
    return (
      <div>
        <Drawer anchor="right" open={open} onClose={onClose}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              width: 325,
            }}
          >
            <AppBar position="static" color="primary">
              <Toolbar
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '0 5px 0 20px',
                }}
              >
                <Typography variant="h6" color="inherit">
                  Shopping bag ({items.length})
                </Typography>
                <IconButton
                  color="inherit"
                  onClick={onClose}
                  aria-label="Close"
                >
                  <ArrowForward />
                </IconButton>
              </Toolbar>
            </AppBar>
            <List style={{ paddingTop: 16, flex: 1, overflow: 'auto' }}>
              {items.map((item, index) => (
                <div key={item.acno}>
                  {index !== 0 && <Divider />}
                  <ListItem ContainerProps={{ style: { marginBottom: 0 } }}>
                    <ListItemText
                      primary={`${item.recordCode} | ${formatRecordDetails(
                        item
                      )}`}
                      secondary={`£${item.price}`}
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        color="inherit"
                        onClick={() => {
                          trackEvent({
                            category: 'Stock',
                            action: 'Remove from bag - sidebar',
                            label: item.acno,
                            value: Number(item.price),
                          })
                          onRemoveItem(item)
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                </div>
              ))}
            </List>
            <Divider />
            <p style={{ margin: '20px 0 0 20px' }}>
              <b>Total:</b> £{calculateTotalPrice(items)}
            </p>
            <Button
              size="large"
              style={{ margin: 20 }}
              variant="contained"
              color="secondary"
              onClick={() => this.setState({ checkoutInProgress: true })}
              disabled={items.length === 0}
            >
              Checkout
            </Button>
          </div>
        </Drawer>
        <Dialog
          open={this.state.checkoutInProgress}
          onClose={() => this.setState({ checkoutInProgress: false })}
          fullScreen={fullScreen}
          scroll="body"
        >
          <IconButton
            aria-label="Close"
            style={{
              position: 'absolute',
              right: 10,
              top: 10,
            }}
            onClick={() => this.setState({ checkoutInProgress: false })}
          >
            <CloseIcon />
          </IconButton>
          <Checkout />
        </Dialog>
      </div>
    )
  }
}

ShoppingCart.propTypes = {
  open: PropTypes.bool,
  items: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  onRemoveItem: PropTypes.func.isRequired,
}

ShoppingCart.defaultProps = {
  items: [],
}

export default withMobileDialog()(ShoppingCart)
