import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import {
  formatAddress,
  formatItemsForReview,
  formatCleaningFee,
  calculateTotalPrice,
  calculateCleaningTotal,
  calculateTotalLps,
} from '../helpers/shopping-cart'
import paymentMethods from '../helpers/payment'
import ReactToPrint from 'react-to-print'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import PrintIcon from '@material-ui/icons/Print'

const styles = theme => ({
  listItem: {
    padding: `${theme.spacing.unit}px 0`,
  },
  total: {
    fontWeight: '700',
  },
  title: {
    marginTop: theme.spacing.unit * 2,
  },
})

function Review(props) {
  const componentRef = useRef()
  const { classes, onChange } = props
  return (
    <div ref={componentRef}>
      <Typography variant="h6" gutterBottom>
        Order summary
      </Typography>
      <List disablePadding>
        {formatItemsForReview(props.items).map(item => (
          <ListItem className={classes.listItem} key={item.name}>
            <ListItemText primary={item.name} secondary={item.desc} />
            <Typography variant="subtitle2">{item.price}</Typography>
          </ListItem>
        ))}
        <FormControlLabel
          control={
            <Checkbox
              color="secondary"
              name="recordsCleaned"
              onChange={e => onChange('recordsCleaned', e.target.checked)}
              checked={props.recordsCleaned}
            />
          }
          label="Would you like your records cleaned?"
        />
        {props.recordsCleaned && (
          <ListItem className={classes.listItem}>
            <ListItemText
              primary={'Cleaning'}
              secondary={`${props.items.length} items (${calculateTotalLps(
                props.items
              )} LPs) @ ${formatCleaningFee()} GBP (£) per LP`}
            />
            <Typography variant="subtitle2">{`£${calculateCleaningTotal(
              props.items
            )}`}</Typography>
          </ListItem>
        )}
        <ListItem className={classes.listItem}>
          <ListItemText primary="Total" />
          <Typography variant="subtitle1" className={classes.total}>
            {`£${calculateTotalPrice(
              props.items,
              props.recordsCleaned
            )} + shipping`}
          </Typography>
        </ListItem>
      </List>
      <Grid item xs={12}>
        <TextField
          id="instructions"
          name="instructions"
          label="Any inquiries or special instructions?"
          fullWidth
          multiline
          rowsMax="4"
          value={props.instructions}
          onChange={e => onChange('instructions', e.target.value)}
        />
      </Grid>
      <Grid container spacing={16}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom className={classes.title}>
            Shipping address
          </Typography>
          <Typography gutterBottom>{`${props.firstName} ${
            props.lastName
          }`}</Typography>
          <Typography gutterBottom>{formatAddress(props)}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom className={classes.title}>
            Payment method
          </Typography>
          <Typography gutterBottom>
            {paymentMethods[props.paymentMethod]}
          </Typography>
        </Grid>
      </Grid>
      <br />
      <ReactToPrint
        trigger={() => (
          <Button
            size="small"
            color="secondary"
            variant="outlined"
          >
            <PrintIcon />&nbsp;Print order summary
          </Button>
        )}
        content={() => componentRef.current}
        pageStyle="margin:50px;"
      />
    </div>
  )
}

Review.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Review)
