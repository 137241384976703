import React from 'react'
import PropTypes from 'prop-types'

const RecordDetailItem = ({ title, content, children }) => (
  <div style={{marginBottom: 5}}>
    <span style={{ fontWeight: 700 }}>{`${title}: `}</span>
    {content || children}
  </div>
)

RecordDetailItem.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
}

export default RecordDetailItem
