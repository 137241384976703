import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import withWidth from '@material-ui/core/withWidth'
import Grid from '@material-ui/core/Grid'
import Navigation from '../components/navigation'
import ShoppingCartContext from '../context/shopping-cart'
import ShoppingCartButton from '../components/shopping-cart-button'
import ShoppingCart from '../components/shopping-cart'
import { Link } from 'gatsby'
import banner from '../images/spiral-header-small-sizes-white.png'
import hero from '../images/hero/turntable.jpg'

const styles = theme => ({
  logo: {
    [theme.breakpoints.down('xs')]: {
      order: 1,
      marginTop: 10,
    },
  },
})

const Header = ({ siteTitle, navigation, classes, width }) => {
  return (
    <ShoppingCartContext.Consumer>
      {value => (
        <div
          style={{
            position: 'relative',
            zIndex: 1,
            backgroundImage: `url(${hero})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        >
          <div
            style={{
              padding: '1.45rem 1.0875rem',
              margin: '0 auto',
              maxWidth: 1200,
            }}
          >
            <Grid container>
              <Grid item xs={12} sm={8} className={classes.logo}>
                <Link to="/">
                  <img src={banner} alt={siteTitle} />
                </Link>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Grid container direction="column" alignItems="flex-end">
                  <ShoppingCartButton
                    value={value.shoppingCart.size}
                    onClick={value.toggleShoppingCart}
                  />
                  <ShoppingCart
                    open={value.shoppingCartOpen}
                    items={value.formatShoppingCart()}
                    onClose={value.toggleShoppingCart}
                    onRemoveItem={value.removeItemFromShoppingCart}
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
          <Navigation items={navigation} collapse={width === 'sm' || width === 'xs'} />
        </div>
      )}
    </ShoppingCartContext.Consumer>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: '',
}

export default withStyles(styles)(withWidth()(Header))
