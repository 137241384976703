import React from 'react'

const InvisibleButton = ({ style, children, ...rest }) => (
  <button
    style={{
      display: 'inherit',
      padding: 0,
      border: 'none',
      outline: 'none',
      font: 'inherit',
      color: 'inherit',
      background: 'none',
      cursor: 'pointer',
      ...style,
    }}
    {...rest}
  >
    {children}
  </button>
)

export default InvisibleButton
