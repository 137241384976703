import React from 'react'

const ShoppingCartContext = React.createContext({
  shoppingCart: new Map(),
  shoppingCartOpen: false,
  toggleShoppingCart: () => {},
  addItemToShoppingCart: () => {},
  removeItemFromShoppingCart: () => {},
  clearShoppingCart: () => {},
  formatShoppingCart: () => {},
});

export default ShoppingCartContext