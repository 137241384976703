import React from 'react'
import Typography from '@material-ui/core/Typography'
import { Link } from 'gatsby'

const StockListHelp = () => (
  <div style={{ padding: 20 }}>
    <Typography variant="h4" gutterBottom>
      Using the stock list
    </Typography>
    <p>
      The list is in price order, from highest to lowest. You can change that
      order from lowest to highest if you wish by clicking on the highlighted
      Price button.
    </p>
    <p>
      If you prefer to see the stock in record code order, just click on "Record
      code" (between "Buy" and "Condition").
    </p>
    <p>
      To see all the information about a record, click on the item. Another
      click will close that display.
    </p>
    <p>
      You can order direct from the stocklist. Either click on the basket icon
      or click "add to bag" when the information is displayed.
    </p>
    <p>
      When your order is submitted, it will be emailed to me automatically. I
      will send you an invoice before I ask you to pay.
    </p>
    <Typography variant="h6" gutterBottom>
      Search by record code
    </Typography>
    <p>
      To find all the SXLs: type SXL in the box under where it says "Search by
      record code". The same for all other series such as ASD, SAX etc. This
      will also find individual record codes if they are in stock.
    </p>
    <p>
      If you want to see all the SXLs, for example, and you want them in number
      order, type SXL into the Search by record code box, then click on "Record
      code" (between "Buy" and "Condition"). You'll find some SXL re-issues
      first, just scroll down a little to find the SXLs.
    </p>
    <Typography variant="h6" gutterBottom>
      Search by other record details
    </Typography>
    <p>
      To find a piece of music (e.g. Fantasia on Greensleeves) type the title of
      the piece in the long search box.
    </p>
    <p>
      To find all the music by a composer type the composer into the long search
      box. The same with conductors and performers.
    </p>
    <p>
      To find all the records by Grumiaux type his name in the long search box.
    </p>
    <p>
      To find all records by Grumiaux <em>and</em> Haskil type both names in the
      long search box.
    </p>
    <p>
      To search for an exact phrase, surround it with quotes in the search box,
      e.g. "du Pre".
    </p>
    <Typography variant="h6" gutterBottom>
      Filter by category
    </Typography>
    <p>
      Click on the down arrow next to "Filter by Category". You will see a long
      list of "Categories", for example VIOLINISTS, CHAMBER MUSIC, TWENTIETH
      CENTURY.
    </p>
    <p>
      Click on the Category you are interested in, and you will see all my stock
      of, for example, violinists.
    </p>
    <p>
      If you wish you can then click on "Details" (between "Condition" and
      "Label") and see violinists in alphabetical order of name.
    </p>
    <p>
      TWENTIETH CENTURY, for another example, will give you my list of Twentieth
      Century Composers, and clicking on "Details" will put the composers into
      alphabetical order
    </p>
    <Typography variant="h6" gutterBottom>
      Labels &amp; pressings
    </Typography>
    <p>
      I give a brief description of the record label where this is helpful. If
      you are not certain that an LP has the right pressing or label for you,
      please ask.
    </p>
    <p>
      Please see my <Link to="/guide-to-collecting">guide to collecting</Link>{' '}
      for much more information, and photos of labels.
    </p>
    <Typography variant="h6" gutterBottom>
      Key to some of the abbreviations and terms I use
    </Typography>
    <p>BavRSO = Bavarian Radio Symphony Orchestra</p>
    <p>BPO = Berlin Philharmonic</p>
    <p>Ch = Choir or Chorus</p>
    <p>CO = Chamber Orchestra</p>
    <p>ECO = English Chamber Orchestra</p>
    <p>foxed = small brown marks on paper</p>
    <p>NPO = National Philharmonic Orchestra</p>
    <p>NYPO = New York Philharmonic</p>
    <p>O = Orchestra</p>
    <p>
      orig = original, the first label for this LP. For example, original ED3
      means that ED3 is is the first label
    </p>
    <p>Phil O = Philharmonia Orchestra</p>
    <p>pr = pressing</p>
    <p>RPO = Royal Philharmonic Orchestra</p>
    <p>s. = side</p>
    <p>sl = sleeve</p>
    <p>SRO = Suisse Romande Orchestra</p>
    <p>SO = Symphony Orchestra</p>
    <p>VPO = Vienna Phiharmonic</p>
  </div>
)

export default StockListHelp
