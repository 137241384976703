const didConsent = () => {
  if (window.cookieconsent) {
    return window.cookieconsent.utils.getCookie('cookieconsent_status') === 'allow'
  }
}


export const trackPageView = title => {
  if (didConsent()) {
    window.dataLayer.push({
      event: 'pageview',
      page: {
        path: document.location.pathname + document.location.search,
        title,
      },
    })
  }
}

export const trackEvent = details => {
  if (didConsent()) {
    const detailsWithDefaults = {
      category: 'None',
      action: 'None',
      label: 'None',
      value: 0,
      ...details,
    }
    window.dataLayer.push({
      event: 'customAnalyticsEvent',
      details: detailsWithDefaults,
    })
  }
}
