import stripHTML from "./strip-html"

function naturalSort(a, b) {
  return new Intl.Collator('en', {
    numeric: true,
    sensitivity: 'base',
  }).compare(stripHTML(a), stripHTML(b))
}

export default naturalSort
