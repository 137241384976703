export function bold(value) {
  return value && `<b>${value}</b>`
}

export function italic(value) {
  return value && `<i>${value}</i>`
}

export function filterAndJoin(list, delimiter = ', ') {
  return list.filter(item => !!item).join(delimiter)
}

export function parentheses(value) {
  return value && `(${value})`
}

export function capitalize(string) {
  return string
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}
